<template>
  <div class="footer">
    <div class="container">
      <div class="image-container">
        <img :src="Img" alt="Logo" />
        <p class="address">
          {{ $t("region") }} <br />
          {{ $t("district") }}
        </p>
        <p class="email">smatWare@gmail.com</p>
      </div>

      <div class="content-box">
        <p class="footer_text">
          {{ $t("footer_text") }}
        </p>
      </div>

      <div class="content-box">
        <h3>{{ $t("Ijtimoiy tarmoqlarimiz") }}</h3>
        <div class="social-icons">
          <div>
            <a
              href="https://instagram.com"
              target="_blank"
              class="social-icon"
              aria-label="Instagram"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </div>
          <div>
            <a
              href="https://t.me/Khusen_Kuvvatov"
              target="_blank"
              class="social-icon"
              aria-label="Telegram"
            >
              <i class="fab fa-telegram-plane"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="bot-sect">© 2024 SMART WARE, Inc.</div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import Img from "@/assets/images/loggo.png";

const { t } = useI18n();
</script>

<style scoped>
.footer {
  width: 100%;
  margin-top: 100px;
  background: #5f73a8;
  /* padding: 40px 20px; */
  color: white;
  text-align: center;
}

.main {
  display: flex;
  flex-wrap: wrap;
  /* align-items: flex-start; */
  justify-content: space-between;
  margin: 0 auto;
  /* border-bottom: 1px solid #fff; */
  /* padding-bottom: 40px; */
}

.image-container {
  margin-top: 40px;
  flex: 1;
  text-align: center;
  padding-right: 20px;
}

.image-container img {
  width: 160px;
  height: auto;
  /* max-height: 300px; */
}

.image-container p {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
}

.content-box {
  color: #224de7;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  flex: 1;
  transition: all 0.3s ease;
  min-width: 200px;
}

.content-box h3 {
  color: #fff;
  font-size: 18px;
  padding: 5px 0;
}

.footer_text {
  color: #ffffff;
  font-size: 14px;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}

.link:hover {
  color: #fff;
  text-decoration: underline;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  filter: grayscale(100%);
  border-radius: 50%;
  /* border: 1px solid white; */
  border: 1px solid #cecece;
  font-size: 16px;
  color: #cecece;
  transition: 0.3s;
}

.social-icon:hover,
.social-icon:focus {
  border: 1px solid white;
  color: #ffffff;
}

.social-icon i {
  font-size: 16px;
}

.bot-sect {
  padding: 12px 0;
  color: #cecece;
  font-size: 12px;
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 10px;
  }

  .main {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  }

  .image-container {
    margin-top: 20px;
    flex: 0 0 100%;
    padding-right: 0;
  }

  .content-box {
    flex: 1;
    text-align: center;
    padding: 15px;
    width: 100%;
    margin: 10px 0;
  }

  .content-box h3 {
    padding: 10px 0;
    font-size: 18px;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }

  .link {
    font-size: 14px;
  }

  .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .social-icon i {
    font-size: 25px;
  }

  .bot-sect {
    font-size: 12px;
    margin-top: 20px;
  }
}
</style>
